import React, { ReactNode } from 'react';
import Navbar from '../navbar';
import '../../styles/global.scss';
import Footer from '../footer';

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <>
      <Navbar />
      <main className='layout-children' >{children}</main>
      <Footer/>
    </>
  );
}

export default Layout;