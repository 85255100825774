import React from 'react';
import './index.scss';

interface CTAButtonProps {
  text: string;
  onClick?: () => void;
  type?: 'primary' | 'secondary';
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
}

function CTAButton({ text, onClick, type = 'primary', buttonType = 'button' }: CTAButtonProps): JSX.Element {
  return (
    <button className={`cta-button cta-button--${type}`} onClick={onClick} type={buttonType}>
      {text}
    </button>
  );
}

export default CTAButton;
