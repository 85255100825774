// src/components/Footer.tsx

import React from "react";
import { Link } from "gatsby";
import "./index.scss";
import CTAButton from "../call-to-action-button";

const Footer = () => {
  const openEmailClient = () => {
    const recipient = "contact@golddustconsultancy.com";
    const subject = "Information Request";
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}`;
    window.location.href = mailtoLink;
  };

  return (
    <footer className="footer">
      <div className="footer__links">
        <Link to="/about">About Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/contact">Contact</Link>
      </div>
      <div className="footer__contact">
        <p>Email: contact@golddustconsultancy.com</p>
        <p>Phone: +355 69 458 7756</p>
      </div>
      <div className="footer__social">
        <a
          href="https://www.linkedin.com/company/105437593"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </div>
      <div className="footer__cta">
        <p>Subscribe to our newsletter for updates</p>
        <CTAButton
          text="Email us"
          type="primary"
          onClick={() => openEmailClient()}
        />
      </div>
      <div className="footer__copyright">
        <p>
          © {new Date().getFullYear()} Goldust Consultancy. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
