import React, { useState } from "react";
import { Link } from "gatsby";
import "./index.scss";
import { StaticImage } from "gatsby-plugin-image";

function Navbar(): JSX.Element {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar__content">
        <div className="navbar__logo">
          <div className="logo">
            <StaticImage
              src="../../images/logos/logopng.png"
              alt="Gold Dust Consultancy Logo"
              placeholder="blurred"
              layout="fixed"
              height={20}
            />
          </div>
          <Link to="/">Goldust Consultancy</Link>
        </div>
        <button
          className={`hamburger ${menuOpen ? "open" : ""}`}
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </button>
      </div>
      <ul className={`navbar__links ${menuOpen ? "navbar__links--open" : ""}`}>
        <li onClick={toggleMenu}>
          <Link to="/">Home</Link>
        </li>
        <li onClick={toggleMenu}>
          <Link to="/services">Services</Link>
        </li>
        <li onClick={toggleMenu}>
          <Link to="/process">Process</Link>
        </li>
        <li onClick={toggleMenu}>
          <Link to="/about">About Us</Link>
        </li>
        <li onClick={toggleMenu}>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
